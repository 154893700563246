import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

type MarkdownProcessorProps = {
  children: string;
};

function MarkdownProcessor({ children }: MarkdownProcessorProps) {
  const processedMarkdownText = children.replace(/^>/gm, '\\>');
  return (
    <Markdown
      remarkPlugins={[remarkGfm]}
      components={{
        code: ({ ...props }) => <>{props.children}</>,
        blockquote: ({ ...props }) => <>{props.children}</>,
      }}
    >
      {processedMarkdownText}
    </Markdown>
  );
}

export default MarkdownProcessor;
