import { useState } from "react";
import { HeartOffIcon } from "lucide-react";
import { Post, useLikePost } from "services/post";
import { cn } from "utils/utils";
import { HeartFilledIcon } from "@radix-ui/react-icons";
import { useAuthContext } from "providers/auth";

type LikePostProps = {
  post: Post;
  className?: string;
};

function LikePost({ post, className }: LikePostProps) {
  const [liked, setLiked] = useState(post.likedByUser || false);
  const [likes, setLikes] = useState(post.likes || 0);
  const [isHovered, setIsHovered] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [clickedWhileHovered, setClickedWhileHovered] = useState(false);

  const { authenticated } = useAuthContext();

  const likePost = useLikePost();
  const handleLike = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      await likePost.mutateAsync({ action: "like", postId: post.id });
      setLiked(true);
      setLikes((prev) => prev + 1);
      console.log("Liked the post");
      if (isHovered) {
        setClickedWhileHovered(true);
      }
    } catch (error) {
      console.error("Failed to like the post:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnlike = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      await likePost.mutateAsync({ action: "unlike", postId: post.id });
      setLiked(false);
      setLikes((prev) => (prev > 0 ? prev - 1 : 0));
      console.log("Unliked the post");
    } catch (error) {
      console.error("Failed to unlike the post:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleClick = () => {
    console.log("Clicked like button", authenticated);
    if (!authenticated.authenticated) return; // Prevent action if not authenticated
    if (liked) {
      handleUnlike();
    } else {
      handleLike();
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setClickedWhileHovered(false);
  };

  const showHeartIcon =
    (!liked) ||
    (liked && !isHovered) ||
    (liked && isHovered && clickedWhileHovered);

  const showHeartOffIcon =
    (liked && isHovered && !clickedWhileHovered);

  const isGrayedOut = !authenticated.authenticated && isHovered;

  return (
    <div className={cn("flex w-full justify-center items-center space-x-2", className)}>
      <button
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded"
        aria-label={liked ? "Unlike post" : "Like post"}
        aria-pressed={liked}
        disabled={isProcessing}
      >
        {showHeartIcon && (
          <HeartFilledIcon
            className={cn(
              'w-6 h-6 transition-opacity duration-200',
              liked ? 'text-red-500' : 'text-gray-400',
              isGrayedOut && 'text-gray-300 cursor-not-allowed opacity-70'
            )}
          />
        )}

        {showHeartOffIcon && (
          <HeartOffIcon
            className={cn(
              'w-6 h-6 transition-opacity duration-200',
              'text-gray-400',
              isGrayedOut && 'text-gray-300'
            )}
          />
        )}
      </button>
      <span className="text-sm text-gray-600">{likes}</span>
    </div>
  );
}

export default LikePost;

