import { cn } from 'utils/utils';
import { Avatar, AvatarFallback, AvatarImage } from '~/ui/avatar';

type IdentityAvatarProps = {
  isEditing?: boolean;
  src?: string;
  alt?: string;
  selected?: boolean;
  className?: string;
};

function IdentityAvatar({ src, alt, selected, className }: IdentityAvatarProps) {
  const selectedState = 'bg-gray-700 border-gray-300 border';
  return (
    <Avatar
      className={cn(
        'bg-gray-950 border border-gray-600 hover:border-gray-300 hover:bg-gray-700',
        className,
        selected && selectedState,
      )}
    >
      <AvatarImage src={src} alt={alt || 'Avatar'} />
      <AvatarFallback>
        <img src={'/shock-pepe.png'} alt='Avatar Fallback' />
      </AvatarFallback>
    </Avatar>
  );
}

export default IdentityAvatar;
